import {createApp} from 'vue' // CHANGE
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './scss/index.scss'


let app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')