<template>
  <form class="login-page">
    <h2>Logowanie</h2>
    <div>
    <label class="inputHolder">
      <span>Login</span>
      <input type="text" v-model="login"/>
    </label>
    <label class="inputHolder">
      <span>hasło</span>
      <input type="password" v-model="password"/>
    </label>
    </div>
    <button @click.prevent="execute">Loguj</button>
    <p>Aby założyć nowe konto lub odzyskać hasło skontaktuj się z administratorem</p>
  </form>
</template>
<script>
import {Api} from "@/utils/api";

export default {
  data() {
    return {login: '', password: ''}
  },
  methods: {
    execute() {
      let promise = Api.Authorization.Login({login: this.login, password: this.password});
      this.$emit('changed', promise)
    }
  }
}
</script>
