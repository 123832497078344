<template>
  <div id="app">
    <div v-if="loginCheck.status==0||loginCheck.status==1" class="loader"></div>
    <login v-else-if="loginCheck.status==2 && !loginCheck.data" @changed="loginCheck.promise=$event"/>
    <template v-else-if="loginCheck.status==2 && loginCheck.data">
      <header>
        <nav>
          <template v-if="loginCheck.data.isAdmin">
            <router-link to="/file">Pliki</router-link>

            <router-link to="/user">Użytkownicy</router-link>
          </template>
        </nav>
        <div v-if="loginCheck.data" class="loggedAs">Zalogowany jako {{ loginCheck.data.firstName }}
          {{ loginCheck.data.lastName }}
          <button @click="logout">Wyloguj</button>
        </div>
      </header>
      <div class="loader" v-if="isRouterViewLoading"></div>
      <router-view v-slot="{ Component }" v-show="!isRouterViewLoading">
        <transition mode="out-in">
          <component class="routerView" :is="Component" @loadingStarted="isRouterViewLoading=true"
                     @loadingCompleted="isRouterViewLoading=false" :loginCheck="loginCheck.data"/>
        </transition>
      </router-view>
    </template>
    <div v-else class="errorPage">
      <p>Wystąpił błąd</p>
    </div>
  </div>
</template>
<script>
import {Api} from "./utils/api";
import ReactivePromiseStatus from "reactive-promise-status/dist/reactive-promise-status";
import Login from "@/views/Login";

export default {
  components: {Login},
  data() {
    return {
      isRouterViewLoading: false
    }
  },
  mounted() {
    this.$store.commit("setLoginPromise", Api.Authorization.Check())
  },
  methods: {
    async logout() {
      this.$store.commit("setLoginPromise", Promise.resolve(null))
      await Api.Authorization.Logout();
    }
  },
  computed: {
    loginCheck() {
      return this.$store.state.loginCheckPromise;
    }
  },
  watch: {
    $route() {
      this.isRouterViewLoading = false;
    }
  }
}
</script>