import * as VueRouter from 'vue-router'
import store from "@/store";

const onlyForAdmin=async (to, from, next) => {
  console.log('onlyForAdmin')
  let prom=store.state.loginCheckPromise.then();
   if((await prom)?.isAdmin) {
     next()
   } else {
     next({ name: 'file' })
   }
}

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'file' }
  },  {
    path: '/user',
    name: 'user',
    component: ()=>import(/* webpackChunkName: "Users", webpackPrefetch: true */'../views/Users.vue'),
    beforeEnter:onlyForAdmin
  },{
    path: '/user/add',
    name: 'userAdd',
    component: ()=>import(/* webpackChunkName: "UserAdd", webpackPrefetch: true */'../views/UserAdd.vue'),
    beforeEnter:onlyForAdmin
  },{
    path: '/user/:id/edit',
    name: 'userEdit',
    component: ()=>import(/* webpackChunkName: "UserEdit", webpackPrefetch: true */'../views/UserEdit.vue'),
    beforeEnter:onlyForAdmin
  },{
    path: '/user/:id/password',
    name: 'changePassword',
    component: ()=>import(/* webpackChunkName: "ChangePassword", webpackPrefetch: true */'../views/ChangePassword.vue'),
    beforeEnter:onlyForAdmin
  },  {
    path: '/file',
    name: 'file',
    component: ()=>import(/* webpackChunkName: "Files", webpackPrefetch: true */'../views/Files.vue')
  },  {
    path: '/file/add',
    name: 'fileAdd',
    component: ()=>import(/* webpackChunkName: "FileAdd", webpackPrefetch: true */'../views/FileAdd.vue')
  },
]


const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  base: process.env.BASE_URL,
  routes,
})

export default router

