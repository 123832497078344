import {createStore} from 'vuex'
import ReactivePromiseStatus from "reactive-promise-status";

export default createStore({
    state: {
        loginCheckPromise: new ReactivePromiseStatus()
    },
    getters: {},
    mutations: {
        setLoginPromise(state, value) {
            state.loginCheckPromise.promise = value;
        }
    },
    actions: {},
    modules: {}
})
